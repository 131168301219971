// @ts-nocheck
import { html, css } from 'lit';
import WarpElement from '@warp-ds/elements-core';
import '@warp-ds/icons/elements/bell-24';
import '@warp-ds/icons/elements/bell-filled-24';
import '@warp-ds/icons/elements/circle-plus-24';
import '@warp-ds/icons/elements/circle-plus-filled-24';
import '@warp-ds/icons/elements/circle-user-24';
import '@warp-ds/icons/elements/circle-user-filled-24';
import '@warp-ds/icons/elements/house-24';
import '@warp-ds/icons/elements/messages-24';
import '@warp-ds/icons/elements/messages-filled-24';

const menuItem = {
    NONE: 'NONE',
    FRONTPAGE: 'FRONTPAGE',
    INBOX: 'INBOX',
    NEW_AD: 'NEW_AD',
    NOTIFICATIONS: 'NOTIFICATIONS',
    PROFILE: 'PROFILE',
    MENU: 'MENU',
};

class FinnTopbar extends WarpElement {
    constructor() {
        super();

        this._translationSupport = false;
    }

    static get styles() {
        // Position fixed makes the header render on each printing page, actually
        // covering text in most browsers. Setting it to default static when
        // printing, only makes it render once, on first page, and hence does not
        // cover important text on the following pages.
        return [
            ...WarpElement.styles,
            css`
                :host {
                    width: 100%;
                    display: block;
                    height: 50px;
                    margin-bottom: 16px;
                }

                header {
                    z-index: 15;
                }

                @media print {
                    header {
                        position: static !important;
                    }
                }

                @media (min-width: 320px) {
                    .mobile-logo {
                        display: block;
                    }

                    .home-icon {
                        display: none;
                    }
                }
                @media (max-width: 319px) {
                    .mobile-logo {
                        display: none;
                    }
                    .logo {
                        flex-grow: 1;
                        margin-left: -16px;
                    }
                }

                @media (max-width: 767px) {
                    .mobile-logo svg {
                        width: 54px;
                    }
                }
                *,:before,:after{--w-rotate:0;--w-rotate-x:0;--w-rotate-y:0;--w-rotate-z:0;--w-scale-x:1;--w-scale-y:1;--w-scale-z:1;--w-skew-x:0;--w-skew-y:0;--w-translate-x:0;--w-translate-y:0;--w-translate-z:0}.page-container{background-color:var(--w-s-color-background);max-width:1010px;margin:0;padding:0 16px}@media (min-width:1300px){.page-container{margin-left:auto;margin-right:auto;padding-left:31px;padding-right:31px}}.bg-inherit{background-color:inherit}.border-b-2{border-bottom-width:2px}.border-\\[--w-color-navbar-border-selected\\],.hover\\:border-\\[--w-color-navbar-border-selected\\]:hover,.focus\\:border-\\[--w-color-navbar-border-selected\\]:focus{border-color:var(--w-color-navbar-border-selected)}.text-\\[--w-color-navbar-icon-selected\\]{color:var(--w-color-navbar-icon-selected)}.no-underline,.hover\\:no-underline:hover,.focus\\:no-underline:focus,.active\\:no-underline:active{text-decoration:none}.flex-auto{flex:auto}.focus\\:outline-none:focus{outline-offset:2px;outline:2px solid #0000}.static{position:static}.s-bg{background-color:var(--w-s-color-background)}.s-text{color:var(--w-s-color-text)}.s-icon-subtle{color:var(--w-s-color-icon-subtle)}.s-border{border-color:var(--w-s-color-border)}.-mb-4{margin-bottom:-.4rem}.ml-8{margin-left:.8rem}.text-12{font-size:var(--w-font-size-xs);line-height:var(--w-line-height-xs)}@media (max-width:479.9px){.lt-sm\\:pr-0{padding-right:0}}@media (min-width:480px){.sm\\:block{display:block}.sm\\:hidden{display:none}}@media (min-width:768px){.md\\:block{display:block}.md\\:flex-none{flex:none}.md\\:ml-10{margin-left:1rem}.md\\:mr-auto{margin-right:auto}.md\\:px-10{padding-left:1rem;padding-right:1rem}.md\\:py-0{padding-top:0;padding-bottom:0}};
            `,
        ];
    }

    static get properties() {
        return {
            loggedIn: { type: Boolean, attribute: 'logged-in' },
            brand: { type: String, attribute: 'brand', reflect: true },
            links: { type: Object, attribute: 'links', reflect: true },
            activeMenuItem: { type: String, attribute: 'active-menu-item' },
            distractionFree: {
                type: Boolean,
                attribute: 'distraction-free',
            },
            sites: { type: String },
            hasNotifications: {
                type: Boolean,
                attribute: 'has-notifications',
                reflect: true,
            },
            messagesUnreadCount: {
                type: Number,
                attribute: 'message-unread-count',
                reflect: true,
            },
            show: { type: Boolean, reflect: true },
            profileImageURL: {
                type: String,
                attribute: 'profile-image-url',
                reflect: true,
            },
            profileName: {
                type: String,
                attribute: 'profile-name',
                reflect: true,
            },
            profileEmail: {
                type: String,
                attribute: 'profile-email',
                reflect: true,
            },
        };
    }

    firstUpdated() {
        // right after first render, set profile image from local storage if available
        // update hook is not called server side
        // cannot use connectedCallback as this messes with hydration
        if (this.loggedIn) {
            this.profileImageURL = localStorage.getItem('profileImageURL') || '';
        }
    }

    set profileImageURL(val) {
        const oldVal = this._profileImageURL;
        this._profileImageURL = val;
        this.requestUpdate('profileImageURL', oldVal);

        try {
            localStorage.setItem('profileImageURL', val);
        } catch (e) {
            console.error(e);
        }
    }

    get profileImageURL() {
        return this._profileImageURL;
    }

    get _origin() {
        if (this.sites) return this.sites;
        return new URL(this.baseURI).origin;
    }

    _trackEventNewAd() {
        this.dispatchEvent(new Event('new-ad'));
    }

    _trackEventLogin() {
        this.dispatchEvent(new Event('login'));
    }

    _trackEventProfile() {
        this.dispatchEvent(new Event('click-profile'));
    }

    _trackEventNotifications() {
        this.dispatchEvent(new Event('click-notifications'));
    }

    _trackEventMessages() {
        this.dispatchEvent(new Event('click-messages'));
    }

    get _legacyProfileImage() {
        const isActive = this.activeMenuItem === menuItem.PROFILE;
        if (this.profileImageURL && !this.profileImageURL.includes('default')) {
            return html`<img
                src="${this.profileImageURL}"
                class="top-bar__menu-item__profile-picture rounded-full"
                alt="${this.i18n.t({
                    id: 'topbar.profile-image.alt',
                    message: 'My profile image',
                    comment: 'Alt text used on the legacy profile image',
                })}"
                width="24"
                height="24"
            />`;
        }

        if (isActive) {
            return html`
                <w-icon-circle-user-filled-24
                    class="-mb-4 text-[--w-color-navbar-icon-selected]"
                ></w-icon-circle-user-filled-24>
            `;
        }

        return html` <w-icon-circle-user-24 class="-mb-4 s-icon-subtle"></w-icon-circle-user-24> `;
    }

    get _legacyProfile() {
        const isActive = this.activeMenuItem === menuItem.PROFILE;
        if (this.loggedIn) {
            const myBrandMessage = this.i18n.t({
                id: 'topbar.link.profile',
                message: 'My {brand}',
                values: { brand: this.brand },
                comment:
                    'Message used in link, as aria label and shown in clear text to the user when linking to the users profile',
            });
            return html`<a
                href="${this._origin}/my-page"
                class="${isActive
                    ? 'border-[--w-color-navbar-border-selected]'
                    : 'border-transparent'} group flex flex-auto md:flex-none justify-center items-center md:py-0 md:px-10 md:ml-10 relative no-wrap text-12 no-underline min-width-0 border-b-2 hover:border-[--w-color-navbar-border-selected] focus:border-[--w-color-navbar-border-selected] focus:outline-none hover:no-underline focus:no-underline active:no-underline"
                aria-current="${isActive ? 'page' : 'false'}"
                aria-label="${myBrandMessage}"
                data-automation-id="profile-link"
                @click=${this._trackEventProfile}
                title="${myBrandMessage}"
            >
                <span data-hj-suppress id="topbar_profile-image">${this._legacyProfileImage}</span>
                <span class="hidden md:block ml-8 text-gray-800 translate font-bold" style="color: #474445;"
                    >${myBrandMessage}</span
                >
            </a>`;
        }

        const loginMessage = this.i18n.t({
            id: 'topbar.link.login',
            message: 'Log in',
            comment:
                'Message used in link, as aria label and shown in clear text to the user when linking to the login page',
        });

        return html`<a
            href="${this._origin}/auth/login"
            class="${isActive
                ? 'border-[--w-color-navbar-border-selected]'
                : 'border-transparent'} group flex flex-auto md:flex-none justify-center items-center md:py-0 md:px-10 md:ml-10 relative no-wrap text-12 no-underline  min-width-0 border-b-2 hover:border-[--w-color-navbar-border-selected] focus:border-[--w-color-navbar-border-selected] focus:outline-none hover:no-underline focus:no-underline active:no-underline"
            aria-current="${isActive ? 'page' : 'false'}"
            aria-label="${loginMessage}"
            data-automation-id="profile-link"
            title="${loginMessage}"
            @click=${this._trackEventLogin}
            rel="nofollow"
        >
            <span data-hj-suppress id="topbar_profile-image">${this._legacyProfileImage}</span>
            <span class="hidden md:block ml-8 text-gray-800 translate font-bold" style="color: #474445;"
                >${loginMessage}</span
            >
        </a>`;
    }

    get _logo() {
        switch (this.brand) {
            case 'TORI':
                return html`
                    <w-icon-house-24 class="-mb-4 s-icon-subtle home-icon"></w-icon-house-24>
                    <span class="mobile-logo">
                        <svg focusable="false" width="64" height="31" viewBox="0 0 64 31">
                            <title>Tori.fi</title>
                            <path
                                fill-rule="evenodd"
                                clip-rule="evenodd"
                                d="M63.9995 3.73685C63.9782 5.77281 62.3051 7.40873 60.2566 7.39666C58.208 7.38458 56.5545 5.72907 56.5575 3.69299C56.5605 1.65692 58.2189 0.00622056 60.2675 0.000108566C61.2644 -0.00749572 62.2224 0.384651 62.9247 1.08789C63.6271 1.79113 64.0145 2.74608 63.9995 3.73685ZM28.789 10.0024C22.5633 10.0024 17.9717 14.4901 17.9717 20.5011C17.9717 26.5122 22.5633 30.9999 28.789 30.9999C35.0353 30.9999 39.6476 26.5122 39.6476 20.5011C39.6476 14.4901 35.0353 10.0024 28.789 10.0024V10.0024ZM28.7892 15.0048C31.8296 15.0048 34.1254 17.2692 34.1254 20.5012C34.1254 23.7332 31.8296 25.9975 28.7892 25.9975C25.7487 25.9975 23.4736 23.7331 23.4736 20.5012C23.4736 17.2692 25.7488 15.0048 28.7892 15.0048V15.0048ZM52.4672 10.0021C50.0059 10.0021 48.0824 10.8462 46.8208 12.7194V10.5374H41.5879V30.485H46.9862V19.6157C46.9862 16.6514 49.3135 15.3133 52.091 15.3133C54.4125 15.3133 55.2801 15.7662 55.2801 15.7662V10.5374C54.3882 10.1729 53.4315 9.9909 52.4672 10.0021V10.0021ZM62.9974 10.5376H57.5991V30.4853H62.9974V10.5376ZM16.5259 10.5376H9.26606V5.16473H3.86775V10.5376H0V15.3135H3.86775V23.0332C3.86775 28.2002 6.41177 30.9999 11.3757 30.9999C13.8071 31.0129 16.1781 30.2475 18.1376 28.8169L15.8732 24.7938C14.777 25.4526 13.5475 25.9975 12.2651 25.9975C10.5898 25.9975 9.26607 25.0917 9.26607 22.6009V15.3135H16.5259L16.5259 10.5376Z"
                                fill="#F94F55"
                            />
                        </svg>
                    </span>
                `;
            case 'DBA':
                return html`
                    <w-icon-house-24 class="-mb-4 s-icon-subtle home-icon"></w-icon-house-24>
                    <span class="mobile-logo">
                        <svg width="76" height="49" viewBox="0 0 76 49" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <g clip-path="url(#clip0_5429_2606)">
                                <path
                                    fill-rule="evenodd"
                                    clip-rule="evenodd"
                                    d="M17.2558 9.14486H21.7099C22.0149 9.1414 22.2655 9.3842 22.2706 9.68822V38.5797C22.2673 38.7241 22.2062 38.8612 22.1009 38.9604C21.9955 39.0596 21.8547 39.1126 21.7099 39.1076H17.9099C17.5857 39.0989 17.2951 38.9058 17.1624 38.6108L16.6951 37.3843C14.8951 38.9493 12.5893 39.8146 10.2009 39.8217C4.5787 39.8217 0 35.0401 0 29.1562C0 23.2723 4.5787 18.4907 10.2009 18.4907C12.5916 18.5005 14.8981 19.3717 16.6951 20.9436V9.6727C16.6984 9.52829 16.7595 9.39119 16.8648 9.29202C16.9702 9.19284 17.111 9.13984 17.2558 9.14486ZM5.591 29.1717C5.591 32.1214 8.08281 34.5122 11.1509 34.5122C14.2189 34.5122 16.7107 32.1214 16.7107 29.1717C16.7107 26.222 14.2189 23.8312 11.1509 23.8312C8.08281 23.8312 5.591 26.222 5.591 29.1717Z"
                                    fill="#00098A"
                                />
                                <path
                                    fill-rule="evenodd"
                                    clip-rule="evenodd"
                                    d="M33.6363 20.9377C35.431 19.3626 37.7391 18.4913 40.1306 18.486C45.7528 18.486 50.3315 23.2807 50.3315 29.1616C50.3315 35.0425 45.7528 39.8217 40.1306 39.8217C37.7399 39.8119 35.4333 38.9411 33.6363 37.37L33.1691 38.5959C33.0315 38.8866 32.7439 39.0777 32.4216 39.0924H28.6216C28.4768 39.0974 28.336 39.0444 28.2306 38.9453C28.1253 38.8462 28.0642 38.7092 28.0609 38.5648V9.67243C28.0642 9.5281 28.1253 9.39107 28.2306 9.29194C28.336 9.19281 28.4768 9.13984 28.6216 9.14486H33.0757C33.2205 9.13984 33.3613 9.19281 33.4666 9.29194C33.572 9.39107 33.6331 9.5281 33.6363 9.67243V20.9377ZM33.6364 29.1461C33.6364 32.0943 36.1282 34.4839 39.1962 34.4839C42.2643 34.4839 44.7561 32.0943 44.7561 29.1461C44.7561 26.1979 42.2643 23.8083 39.1962 23.8083C36.1282 23.8083 33.6364 26.1979 33.6364 29.1461Z"
                                    fill="#00098A"
                                />
                                <path
                                    fill-rule="evenodd"
                                    clip-rule="evenodd"
                                    d="M71.1808 19.2541H75.3078C75.4526 19.2491 75.5934 19.3021 75.6988 19.4013C75.8041 19.5006 75.8652 19.6377 75.8685 19.7822V38.6533C75.8652 38.7977 75.8041 38.9349 75.6988 39.0341C75.5934 39.1333 75.4526 39.1864 75.3078 39.1814H71.5078C71.1836 39.1727 70.893 38.9795 70.7603 38.6843L70.293 37.4573C68.493 39.023 66.1872 39.8888 63.7988 39.8958C58.1766 39.8958 53.5979 35.112 53.5979 29.2255C53.5979 23.339 58.1766 18.5552 63.7988 18.5552C66.1882 18.5566 68.4959 19.4231 70.293 20.9937L70.5266 19.7667C70.6043 19.4686 70.872 19.2589 71.1808 19.2541ZM64.7488 34.5529C67.8012 34.5529 70.293 32.1765 70.3086 29.241V29.1789C70.293 26.2434 67.8168 23.867 64.7488 23.867C61.6807 23.867 59.1889 26.2589 59.1889 29.2099C59.1889 32.161 61.6963 34.5529 64.7488 34.5529Z"
                                    fill="#00098A"
                                />
                            </g>
                            <defs>
                                <clipPath id="clip0_5429_2606">
                                    <rect width="75.8684" height="31" fill="white" transform="translate(0 9)" />
                                </clipPath>
                            </defs>
                        </svg>
                    </span>
                `;
            default:
                return html`
                    <span class="sm:hidden">
                        <svg focusable="false" width="52" height="27" viewBox="0 0 124 64">
                            <title>FINN.no</title>
                            <path
                                fill="#06bffc"
                                d="M119.8 58V6c0-1-.8-1.9-1.9-1.9H66c-1 0-1.9.8-1.9 1.9v53.8H118c1 0 1.8-.8 1.8-1.8"
                            />
                            <path
                                fill="#0063fc"
                                d="M22.5 4.2H6C5 4.2 4.2 5 4.2 6v52c0 1 .8 1.9 1.9 1.9H60V41.5C59.9 20.9 43.2 4.2 22.5 4.2"
                            />
                            <path
                                fill="#fff"
                                d="M118 0H66c-3.3 0-6 2.7-6 6v17.4C53.2 9.6 38.9 0 22.5 0H6C2.7 0 0 2.7 0 6v52c0 3.3 2.7 6 6 6h112c3.3 0 6-2.7 6-6V6c0-3.3-2.7-6-6-6m1.8 58c0 1-.8 1.9-1.9 1.9H64.1V6c0-1 .8-1.9 1.9-1.9h52c1 0 1.9.8 1.9 1.9v52zM4.2 58V6C4.2 5 5 4.2 6 4.2h16.5c20.6 0 37.4 16.8 37.4 37.4v18.3H6c-1-.1-1.8-.9-1.8-1.9"
                            />
                        </svg>
                    </span>
                    <span class="flex items-center">
                        <span class="hidden sm:block">
                            <svg focusable="false" width="92" height="32" viewBox="0 0 184 64">
                                <title>FINN.no</title>
                                <path
                                    fill="#06bffc"
                                    d="M179.8 58V6c0-1-.8-1.9-1.9-1.9H66c-1 0-1.9.8-1.9 1.9v53.8H178c1 0 1.8-.8 1.8-1.8"
                                ></path>
                                <path
                                    fill="#0063fc"
                                    d="M22.5 4.2H6C5 4.2 4.2 5 4.2 6v52c0 1 .8 1.9 1.9 1.9H60V41.5C59.9 20.9 43.2 4.2 22.5 4.2"
                                ></path>
                                <path
                                    fill="#fff"
                                    d="M178 0H66c-3.3 0-6 2.7-6 6v17.4C53.2 9.6 38.9 0 22.5 0H6C2.7 0 0 2.7 0 6v52c0 3.3 2.7 6 6 6h172c3.3 0 6-2.7 6-6V6c0-3.3-2.7-6-6-6m1.8 58c0 1-.8 1.9-1.9 1.9H64.1V6c0-1 .8-1.9 1.9-1.9h112c1 0 1.9.8 1.9 1.9v52zM4.2 58V6C4.2 5 5 4.2 6 4.2h16.5c20.6 0 37.4 16.8 37.4 37.4v18.3H6c-1-.1-1.8-.9-1.8-1.9"
                                ></path>
                                <path
                                    fill="#fff"
                                    d="M110.1 21.1h-4.2c-.7 0-1.2.5-1.2 1.2v19.3c0 .7.5 1.2 1.2 1.2h4.2c.7 0 1.2-.5 1.2-1.2V22.3c0-.6-.6-1.2-1.2-1.2m-12 0H83c-.7 0-1.2.5-1.2 1.2v19.3c0 .7.5 1.2 1.2 1.2h4.2c.7 0 1.2-.5 1.2-1.2v-4h7.7c.7 0 1.2-.5 1.2-1.2v-3.2c0-.7-.5-1.2-1.2-1.2h-7.7v-4.9h9.7c.7 0 1.2-.5 1.2-1.2v-3.7c0-.5-.6-1.1-1.2-1.1m62.8 0h-4.2c-.7 0-1.2.5-1.2 1.2v9.5l-6.6-10c-.3-.4-.8-.7-1.3-.7h-3.2c-.7 0-1.2.5-1.2 1.2v19.3c0 .7.5 1.2 1.2 1.2h4.2c.7 0 1.2-.5 1.2-1.2v-9.4l6.5 9.8c.3.4.8.7 1.3.7h3.4c.7 0 1.2-.5 1.2-1.2V22.3c-.1-.6-.6-1.2-1.3-1.2m-25.4 0h-4.2c-.7 0-1.2.5-1.2 1.2v9.5l-6.6-10c-.3-.4-.8-.7-1.3-.7H119c-.7 0-1.2.5-1.2 1.2v19.3c0 .7.5 1.2 1.2 1.2h4.2c.7 0 1.2-.5 1.2-1.2v-9.4l6.5 9.8c.3.4.8.7 1.3.7h3.4c.7 0 1.2-.5 1.2-1.2V22.3c-.1-.6-.6-1.2-1.3-1.2"
                                ></path>
                            </svg>
                        </span>
                        <span class="font-bold text-12 ml-8 s-text hidden md:block"
                            >${this.i18n.t({
                                id: 'topbar.subtitle',
                                message: 'Market of opportunities',
                                comment: 'The subtitle used after the brand name',
                            })}</span
                        >
                        <span> </span
                    ></span>
                `;
        }
    }

    get _notificationIcon() {
        if (this.activeMenuItem === menuItem.NOTIFICATIONS) {
            return html`
                <w-icon-bell-filled-24 class="-mb-4 text-[--w-color-navbar-icon-selected]"> </w-icon-bell-filled-24>
            `;
        }
        return html` <w-icon-bell-24 class="-mb-4 s-icon-subtle"></w-icon-bell-24>`;
    }

    get _newAdIcon() {
        if (this.activeMenuItem === menuItem.NEW_AD) {
            return html`<w-icon-circle-plus-filled-24
                class="-mb-4 text-[--w-color-navbar-icon-selected]"
            ></w-icon-circle-plus-filled-24>`;
        }
        return html`<w-icon-circle-plus-24 class="-mb-4 s-icon-subtle"></w-icon-circle-plus-24>`;
    }

    get _messagesIcon() {
        if (this.activeMenuItem === menuItem.INBOX) {
            return html`
                <w-icon-messages-filled-24 class="-mb-4 text-[--w-color-navbar-icon-selected]">
                </w-icon-messages-filled-24>
            `;
        }
        return html`<w-icon-messages-24 class="-mb-4 s-icon-subtle"></w-icon-messages-24>`;
    }

    render() {
        const finnLogoAriaLabel = this.i18n.t({
            id: 'topbar.header.logo.aria-label',
            message:
                'the FINN.no logo shows the name FINN written in white text in a light blue rectangle to the right of a dark blue quarter circle.',
            comment: 'Aria label for the FINN.no logo',
        });
        const frontpageText = this.i18n.t({
            id: 'topbar.header.frontpage.link.aria-label',
            message: 'Frontpage',
            comment: 'Aria label and title used for the frontpage link in the header ',
        });
        const newAdText = this.i18n.t({
            id: 'topbar.header.new-ad.link',
            message: 'New ad',
            comment: 'Text for the new ad link in the header',
        });
        const messagesText = this.i18n.t({
            id: 'topbar.header.messages.text',
            message: 'Messages',
            comment: 'Text for the messages link in the header',
        });

        return html`
            <header class="s-bg border-b s-border w-full fixed z-20" data-browser-warning="true">
                <span role="img" aria-label="${finnLogoAriaLabel}"></span>
                <nav
                    class="bg-inherit page-container flex notranslate lt-sm:pr-0"
                    style="height:49px"
                    aria-label="Topp"
                >
                    <a
                        href="${this._origin}"
                        class="logo flex justify-center items-center md:mr-auto border-b-2 border-transparent hover:border-[--w-color-navbar-border-selected] focus:border-[--w-color-navbar-border-selected] focus:outline-none text-grey-800 no-underline hover:no-underline focus:no-underline active:no-underline "
                        aria-current="${this.activeMenuItem === menuItem.FRONTPAGE ? 'page' : 'false'}"
                        aria-label="${frontpageText}"
                        data-automation-id="frontpage-link"
                        title="${frontpageText}"
                    >
                        ${this._logo}
                    </a>
                    ${this.distractionFree
                        ? ''
                        : html`
                              <a
                                  id="top-bar_notifications-link"
                                  href="${this._origin}/notifications"
                                  class="${this.activeMenuItem === menuItem.NOTIFICATIONS
                                      ? 'border-[--w-color-navbar-border-selected]'
                                      : 'border-transparent'} group flex flex-auto md:flex-none justify-center items-center md:py-0 md:px-10 md:ml-10 relative no-wrap text-12 no-underline  min-width-0 border-b-2 hover:border-[--w-color-navbar-border-selected] focus:border-[--w-color-navbar-border-selected] focus:outline-none hover:no-underline focus:no-underline active:no-underline"
                                  aria-current="${this.activeMenuItem === menuItem.NOTIFICATIONS ? 'page' : 'false'}"
                                  aria-label="${this.i18n.t({
                                      id: 'topbar.notifications.link.aria-label',
                                      message: 'Notifications',
                                      comment:
                                          'The aria-label used for the notifications link when the user has no notifications',
                                  })}"
                                  data-automation-id="notifications-link"
                                  title="${this.i18n.t({
                                      id: 'topbar.notifications.link.title',
                                      message: 'Notifications',
                                      comment: 'The title for the notifications link',
                                  })}"
                                  @click="${this._trackEventNotifications}"
                                  rel="nofollow"
                              >
                                  ${this._notificationIcon}
                                  <notification-bubble ?show="${this.hasNotifications}"></notification-bubble>
                                  <span class="hidden md:block ml-8 s-text translate font-bold" style="color: #474445;"
                                      >${this.i18n.t({
                                          id: 'topbar.notifications.link.text',
                                          message: 'Notifications',
                                          comment: 'The link text used on the notifications button',
                                      })}</span
                                  >
                              </a>
                              <a
                                  href="${this._origin}${this.links.newAd}"
                                  class="${this.activeMenuItem === menuItem.NEW_AD
                                      ? 'border-[--w-color-navbar-border-selected]'
                                      : 'border-transparent'} group flex flex-auto md:flex-none justify-center items-center md:py-0 md:px-10 md:ml-10 relative no-wrap text-12 no-underline min-width-0 border-b-2 hover:border-[--w-color-navbar-border-selected] focus:border-[--w-color-navbar-border-selected] focus:outline-none hover:no-underline focus:no-underline active:no-underline"
                                  aria-current="${this.activeMenuItem === menuItem.NEW_AD ? 'page' : 'false'}"
                                  aria-label="${newAdText}"
                                  data-automation-id="new-ad-link"
                                  title="${newAdText}"
                                  @click="${this._trackEventNewAd}"
                                  rel="nofollow"
                              >
                                  ${this._newAdIcon}
                                  <span class="hidden md:block ml-8 s-text translate font-bold" style="color: #474445;"
                                      >${newAdText}</span
                                  >
                              </a>

                              <a
                                  id="top-bar_messages-link"
                                  href="${this._origin}/messages"
                                  class="${this.activeMenuItem === menuItem.INBOX
                                      ? 'border-[--w-color-navbar-border-selected]'
                                      : 'border-transparent'} group flex flex-auto md:flex-none justify-center items-center md:py-0 md:px-10 md:ml-10 relative no-wrap text-12 no-underline  min-width-0 border-b-2 hover:border-[--w-color-navbar-border-selected] focus:border-[--w-color-navbar-border-selected] focus:outline-none hover:no-underline focus:no-underline active:no-underline"
                                  aria-current="${this.activeMenuItem === menuItem.INBOX ? 'page' : 'false'}"
                                  aria-label="${messagesText}"
                                  data-automation-id="inbox-link"
                                  title="${messagesText}"
                                  @click="${this._trackEventMessages}"
                                  rel="nofollow"
                              >
                                  ${this._messagesIcon}
                                  <messages-bubble ?show="${this.messagesUnreadCount > 0}"></messages-bubble>
                                  <span class="hidden md:block ml-8 s-text translate font-bold" style="color: #474445;"
                                      >${messagesText}</span
                                  >
                              </a>

                              ${this._legacyProfile}
                          `}
                </nav>
            </header>
        `;
    }
}

customElements.define('finn-topbar', FinnTopbar);
